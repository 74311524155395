import React, { Fragment } from "react";
import Banner from "./Banner";

import Clients from "./Clients";

import "swiper/css";
import "swiper/css/autoplay";
import Portfolio from "./Portfolio";

function Content() {
  return (
    <Fragment>
      <Banner />
      <Portfolio />
      <Clients />
    </Fragment>
  );
}

export default Content;
