import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const BrandActivation = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Brand Activation</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Brand Activation</h3>
                <p class="service-details__text-1">
                  The ultimate brand experiences are creatively driven by the
                  understanding of a brand’s purpose at a strategic level. We
                  believe only then ‘re you able to earn an extendable-
                  impressionable engagements with consumers; you’re able to form
                  a stronger relationships with them. So it’s important that a
                  brand activation has the right elements in place that allows
                  your audience to relive an experience and share it for days,
                  weeks and years on- end <br />
                  <br />
                  We align our experiential strategy with the core values and
                  attributes of your brand and the ‘unexpected expectations’ of
                  your audience to elevate engagement and entrench your brand’s
                  influence in their lives and culture. Through embracing both
                  art and science, we can craft and tell real stories with real
                  emotions that create authentic connections and stimulate
                  desired reactions.
                  <br />
                  <br />
                  We experience the world through our five senses – taste,
                  sight, smell, touch, and sound – which leaves traditional
                  marketing severely limited in terms of long-term impact. Brand
                  Activations, however, create memories through experiences, and
                  memories are extraordinarily powerful.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default BrandActivation;
