import React from 'react'

function Portfolio() {
    return (

<section className="gallery-one">
    <div className="container-fluid">
        <div className="row masonary-layout">
            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_1.jpg" alt="" />
                    <a href="/kwv" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_7.jpg" alt="" />
                    <a href="/hifl-2019" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_4.jpg" alt="" />
                    <a href="/apple-ipad-air" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_5.jpg" alt="" />
                    <a href="/elite-awards-2019" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_6.jpg" alt="" />
                    <a href="/hifl-2019" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_3.jpg" alt="" />
                    <a href="/ekopolitan" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>


            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_8.jpg" alt="" />
                    <a href="/iphone-13" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-3">
                <div className="gallery-one__card">
                    <img src="assets/images//portfolio/gallery_9.jpg" alt="" />
                    <a href="/onga" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="gallery-one__card">
                    <img src="assets/images/portfolio/gallery_2.jpg" alt="" />
                    <a href="assets/images/portfolio/gallery_2.jpg" className="img-popup">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Portfolio