import React, { Component, Fragment } from 'react'
import ServiceList from './ServiceList'
import Clients from '../Home/Clients'


class Content extends Component {
    render() {
        return (
            <Fragment>
                <ServiceList/>
                <Clients />
            </Fragment>
        );
    }
}

export default Content;