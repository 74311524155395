import React, { Component, Fragment } from 'react';
import Header from '../../../layouts/Header'
import Footer from '../../../layouts/Footer'
import ServiceCategories from '../ServiceCategories'

class PopAndMobileStores extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <section class="page-header">
                    <div class="page-header-shape-1"></div>
                    <div class="page-header-shape-2"></div>
                    <div class="container">
                        <div class="page-header__inner">
                            <ul class="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><span>.</span></li>
                                <li>Service Details</li>
                            </ul>
                            <h2>Pop Up And Mobile Stores</h2>
                        </div>
                    </div>
                </section>
                <section class="service-details">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-8 col-lg-7">
                                <div class="service-details__left">
                                    {/* <div class="service-details__img">
                                        <img src="assets/images/resources/service-details-img.jpg" alt="" />
                                    </div> */}
                                    <div class="service-details__icon-box">
                                        <span class="icon-front-end"></span>
                                    </div>
                                    <h3 class="service-details__title">Pop Up And Mobile Stores</h3>
                                    <p class="service-details__text-1">Pop-up/Mobile stores represent an exciting and place for content to be generated and for a brand to tell its story or better still, extend it . Pop-up/Mobile stores naturally generate interest and gain attention because they are short-lived entities – here now, gone tomorrow – and consumers/audience don’t want to miss an opportunity to gain a valuable experience or form an impression about the brand. <br/><br/>Pop-up/Mobile stores are a creative canvas/platform to test out ideas that push the frontiers of how – and where – to engage or interact with consumers.
                                    Pop-up/Mobile stores provide consumers an escape from a traditional brand experience into the unique brand themed milieu. By putting your brand in an environment that is not the norm we can have a more resonating resounding impact on changing perceptions and creating appeal. Consumers are able to experience a brand’s values and this is start of building a connection. If done right, pop-up/mobile stores are positioned to engage consumers at/on a more intimate level.<br/><br/>
                                    If your brand is considering a pop-up/mobile store to engage consumers on a meaningful level, please do contact attributes through innovative us . We will be happy to discuss the creative ways we can help showcase your brand’s finest ways and story telling</p>                                   
                                </div>
                            </div>
                            <ServiceCategories />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default PopAndMobileStores;
