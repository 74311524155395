import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const IdeationDesign = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Ideation And Design</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Ideation And Design</h3>
                <p class="service-details__text-1">
                  Our environment provides us a lot of elements to inspire our
                  creativity and thought processes, we are constantly on the
                  look out for clues, subtle nuances and kaleidoscope of
                  information from our ever changing environment – this helps
                  fuel our deliveries across our engagement. <br />
                  <br />
                  At Tribes design means more than aesthetics to us; design
                  should or must necessarily evoke some emotion through
                  communicating a message. Design must accentuate our ideas in
                  form and function through transforming otherwise bland spaces
                  and elements into impressive works of art thereby turning
                  places and objects into like-able, living, breathable works of
                  expression; thereby helping brands communicate to their
                  audience what they stand for.- this prospects never ceases to
                  excite us.
                  <br />
                  <br />
                  These days people are bombarded with a myriad of brands often.
                  They are increasingly more likely to reject an offer or
                  intrusion that does not capture their fancy thus underscoring
                  the importance of a scrips impressionable designs either in
                  advertising, social, digital or experiential. Leveraging
                  design helps keep brands unique refreshed, interesting and
                  pleasantly different.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default IdeationDesign;
