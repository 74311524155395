import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Kmv = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img src="assets/images/portfolio/kmv/kmv_bg.jpg" alt="" />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">KWV</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Activation</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2022</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="/#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="/#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="/#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="/#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  KWV a renowned par Excellence wine, spirit, liqour and
                  non-alcoholic beverage producing company intend to create
                  visibility, drive planogram and off-take for its brands in
                  Modern Trade and HORECA to engender sales and engage consumers
                  through sampling and tactical promotions.
                </p>
                <h2 class="portfolio-fullwidth__title">AGENCY TASK</h2>
                <p class="portfolio-fullwidth__text-1">
                  Agency was tasked with the responsibility to develop an
                  activation plan that is aimed at creating a positive
                  perception of KMV brands, increase brand awareness and seeding
                  the brand in the minds of consumers.
                </p>
                <h2 class="portfolio-fullwidth__title">INSIGHT</h2>
                <p class="portfolio-fullwidth__text-1">
                  KWV brands is produce with vast chemistry of Modern and Global
                  best practices with home grown ingredients rooted in tradition
                  hence our desire to stimulate and contribute to the reach of
                  healthy and well sought-after premium drinks.
                </p>
                <h2 class="portfolio-fullwidth__title">THE OUTCOME</h2>
                <ul class="portfolio-fullwidth__text-1">
                  <li>2000+ Shoppers engaged.</li>
                  <li>1500 + Consumers Engaged at various bars.</li>
                  <li>
                    The consumers attest the drinks taste smooth and good while
                    they enthusiastically receive the brand as they were sampled
                    resulting to many of them opt for purchase.
                  </li>
                  <li>
                    Sales increase and brand perception has spread to a wider
                    audience.
                  </li>
                </ul>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/kmv" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/iphone-13" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-g1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-g2.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-g3.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv_g4.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-g5.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-re.jpg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Kmv;
