import React, { Component, Fragment } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/Home/Content";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Header />

        <Content />

        <Footer />
      </Fragment>
    );
  }
}

export default Home;
