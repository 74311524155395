import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

function Clients() {
  return (
    <section className="brand-one brand-one__border-top">
      <div className="container">
        <div
          className="thm-swiper__slider swiper-container"
          data-swiper-options='{"spaceBetween": 100, "slidesPerView": 5, "autoplay": { "delay": 5000 }, "breakpoints": {
          "0": {
              "spaceBetween": 30,
              "slidesPerView": 2
          },
          "375": {
              "spaceBetween": 30,
              "slidesPerView": 2
          },
          "575": {
              "spaceBetween": 30,
              "slidesPerView": 3
          },
          "767": {
              "spaceBetween": 50,
              "slidesPerView": 4
          },
          "991": {
              "spaceBetween": 50,
              "slidesPerView": 5
          },
          "1199": {
              "spaceBetween": 100,
              "slidesPerView": 5
          }
      }}'
        ></div>

        <Swiper
          spaceBetween={50}
          modules={[Autoplay]}
          autoplay={true}
          slidesPerView={6}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            {" "}
            <img src="./assets/images/brand/ic.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/images/brand/bridge.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/images/brand/firestone.png" alt="" />
          </SwiperSlide>

          <SwiperSlide>
            <img src="./assets/images/brand/lagos.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src="./assets/images/brand/ong.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src="./assets/images/brand/psem.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src="./assets/images/brand/w.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src="./assets/images/brand/9mobile.jpg" alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
export default Clients;
