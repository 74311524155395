import React, { Fragment } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { useState } from "react";

function PortfolioPage() {
  const [load, setLoad] = useState({ display: "none" });
  const [button, setButton] = useState({
    display: "inline",
    cursor: "pointer",
  });
  function more() {
    setLoad({ display: "flex" });
    setButton({ display: "none" });
  }

  return (
    <Fragment>
      <Header />
      <section className="page-header">
        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Portfolio</li>
            </ul>
            <h2>Portfolio</h2>
          </div>
        </div>
      </section>
      <section className="portfolio-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ul className="portfolio-filter style1 post-filter has-dynamic-filters-counter list-unstyled">
                <li data-filter=".filter-item" className="active">
                  <span className="filter-text">All</span>
                </li>
                <li data-filter=".bra">
                  <a href="/activation">
                    <span className="filter-text">Activation</span>
                  </a>
                </li>
                <li data-filter=".illus">
                  <a href="/events">
                    <span className="filter-text">Events</span>
                  </a>
                </li>

                <li data-filter=".photo">
                  <a href="/ideation">
                    <span className="filter-text last-pd-none">Ideation</span>
                  </a>
                </li>

                {/* <li data-filter=".web"><span className="filter-text last-pd-none">Web design</span></li> */}
              </ul>
            </div>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/kmv/kmv-g2.jpg" alt="" />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">KWV</p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/kwv">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/kmv">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g2.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        Iphone 13
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/iphone-13">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/iphone-13">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g8.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        Apple ipad Air
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/apple-ipad-air">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/apple-ipad-air">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/elite21/elite21_g4.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        ELITE AWARDS 2021
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/elite-awards-2021">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/elite-awards-2021">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/hifl21/hifl21-g1.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        HIFL 2021
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/hifl-2021">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/hifl-2021">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/elite-19/elite19-re.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        ELITE AWARDS 2019
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/elite-awards-2019">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/elite-awards-2019">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g1.jpg" alt="" />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        HIFL 2019
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/hifl-2019">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/hifl-2019">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/elite-18/elite-g218.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        ELITE AWARDS 2018
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/elite-awards-2018">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/elite-awards-2018">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/eko/eko-g1.jpg" alt="" />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        EKOPOLITAN EXPERIENCE
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/ekopolitan">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/ekopolitan">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row filter-layout masonary-layout" style={load}>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/pecadomo/pacadomo-g3.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        PECADOMO
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/pecadomo">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/pecadomo">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/onga/onga.jpg" alt="" />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">ONGA</p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/onga">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/onga">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/kewalram/kewalram.jpg"
                    alt=""
                  />
                  <div className="portfolio-one__experience">
                    <div className="portfolio-one__web-design">
                      <p className="portfolio-one__web-design-title">
                        KEWALRAM
                      </p>
                    </div>
                    <div className="portfolio-one__fimlor">
                      <p className="portfolio-one__fimlor-title">
                        <a href="/kewalram">The Brief</a>
                      </p>
                    </div>
                  </div>
                  <div className="portfolio-one__arrow">
                    <a href="/kewalram">
                      <span className="icon-right-arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="portfolio-page__btn-box text-center">
              <div style={button}>
                <div className="thm-btn portfolio-page__btn" onClick={more}>
                  <span>Load more work</span>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default PortfolioPage;
