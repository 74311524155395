import React, { Component, Fragment } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/About/Content";

class About extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Content />
        <Footer />
      </Fragment>
    );
  }
}

export default About;
