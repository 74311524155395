import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Hifl = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img src="assets/images/portfolio/hifl/hifl-bg.jpg" alt="" />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">
                        Pace/Higher Institution Football League
                      </p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Activation</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2019</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="/#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="/#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="/#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="/#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  Pace Sport and Entertainment Marketing(PSEM) owners of the
                  HISL/HIFL platform charged TM’s to deliver a compelling end of
                  season award event to underscore the success of the highly
                  Impact-ful HIFL games which held across sixteen cities to fete
                  it’s stakeholders
                </p>
                <h2 class="portfolio-fullwidth__title">THE THOUGHTS</h2>
                <p class="portfolio-fullwidth__text-1">
                  Our focus on this task was to deliver a truly exciting evening
                  of awards and recognition to the event partners/sponsors,
                  officials and players.
                </p>
                <h2 class="portfolio-fullwidth__title">THE EXECUTION</h2>
                <p class="portfolio-fullwidth__text-1">
                  We brought together our best hands in sports hospitality and
                  craft of social engagement to piece together all the elements
                  into an impressive spectacle to immense the audience into an
                  enthralling experience of wholesome impact
                </p>
                <h2 class="portfolio-fullwidth__title">THE OUTCOME</h2>
                <ul class="portfolio-fullwidth__text-1">
                  <li>
                    The broad spectrum of invitees and key corporates in
                    finance, FMCG, Insurance, the academia, sports
                    administration and management where fully sated with series
                    of creative expositions beyond their expectation and could
                    not hide their satisfaction
                  </li>
                  <li>
                    They all endorsed and confirmed their commitment to the HIFL
                    brand well into the foreseeable future
                  </li>
                  <li>
                    The event further strengthened the place and contribution of
                    Nigeria in the global sports entertainment and development
                    sector
                  </li>
                  <li>
                    The iconic global brands in sports marketing and management
                    (LaLiga, Goal.com) through this event sought a strategic
                    long term relationship and partnership
                  </li>
                </ul>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/elite-awards-2019" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="/#"></a>
                  </li>
                  <li class="count">
                    <a href="/#"></a>
                  </li>
                  <li class="count">
                    <a href="/#"></a>
                  </li>
                  <li class="count">
                    <a href="/#"></a>
                  </li>
                  <li class="previous">
                    <a href="/elite-awards-2018" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g3.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g4.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g5.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g6.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/hifl/hifl-g7.jpg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Hifl;
