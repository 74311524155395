import React, { Component, Fragment } from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Ekopolitan = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img src="assets/images/portfolio/eko/eko-bg.jpg" alt="" />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">
                        Lagos State Government
                      </p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Events</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2017</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  Lagos state was celebrating 50yrs of it’s existence as the
                  most dynamic and progressive state in Nigeria; the mood and
                  expectations it’s citizenry from all works of life called for
                  a celebration that only Lagos can deliver.
                </p>
                <h2 class="portfolio-fullwidth__title">THE THOUGHTS</h2>
                <p class="portfolio-fullwidth__text-1">
                  We crafted(telling) the story of Lagos through pers pective
                  (eyes) of it’s citizenry, we sought to unearth the nuances and
                  speaks that the city had become known for through the spectrum
                  of generations of people, events, experiences and more …
                </p>
                <h2 class="portfolio-fullwidth__title">THE EXECUTION</h2>
                <p class="portfolio-fullwidth__text-1">
                  We developed a series of elements and themes that are uniquely
                  Lagos and resonates with it’s citizen into a ball of
                  celebration called the Ekopolitan Experience that brings all
                  together all laying claim to what truly belongs to them true
                  and through.
                </p>
                <h2 class="portfolio-fullwidth__title">THE OUTCOME</h2>
                <p class="portfolio-fullwidth__text-1">
                  This ideas and concept where accepted and endorsed by LASG and
                  was used as the tourism, entertainment and hospitality
                  platform to showcase Lagos to the world at the Notttinghill
                  Carnival-2017
                </p>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/elite-awards-2018" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/pecadomo" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/eko/eko-g1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/eko/eko-g2.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img src="assets/images/portfolio/eko/eko-g3.jpg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Ekopolitan;
