import React, { Component } from 'react';


class Footer extends Component {

    render() {
    
        return (
            <footer className="site-footer">
                <div className="site-footer__top">
                    {/* <div className="site-footer-top-bg"
                        style="background-image: url(assets/images/backgrounds/site-footer-bg.jpg)">
                        
                    </div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="footer-widget__column footer-widget__about">
                                    <div className="footer-widget__about-logo">
                                        <a href="/"><img src="assets/images/logo/logo.png" alt="" /></a>
                                    </div>
                                    <p className="footer-widget__about-text">Welcome to our experiential and marketing agency where we create experientials solutions and services that deliver results</p>
                                    <div className="footer-widget__about-social-list">
                                        {/* <a href="/#"><i className="fab fa-twitter"></i></a> */}
                                        <a href="/#" className="clr-fb"><i className="fab fa-facebook"></i></a>
                                        {/* <a href="/#" className="clr-dri"><i className="fab fa-pinterest-p"></i></a> */}
                                        <a href="https://www.instagram.com/tribesmktng/" className="clr-ins"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                <div className="footer-widget__column footer-widget__explore clearfix">
                                    <h3 className="footer-widget__title">Explore</h3>
                                    <ul className="footer-widget__explore-list list-unstyled">
                                        <li><a href="/about">About</a></li>
                                        <li><a href="/#">Meet our team</a></li>
                                        <li><a href="/portfolio">Portforlio</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="footer-widget__column footer-widget__contact">
                                    <h3 className="footer-widget__title">Contact</h3>
                                    <p className="footer-widget__contact-text">7 Talabi Street Off Adeniyi Jones, Ikeja, Lagos, Nigeria.</p>
                                    <div className="footer-widget__contact-info">
                                        <p>
                                            <a href="tel:+234 811 359 6177" className="footer-widget__contact-phone">+234 811 359 6177</a>
                                            <a href="tel:+234 813 972 1625" className="footer-widget__contact-phone">+234 813 972 1625</a>
                                            <a href="mailto:needhelp@company.com"
                                                className="footer-widget__contact-mail">info@tribesmktng.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="footer-widget__column footer-widget__newsletter">
                                    <h3 className="footer-widget__title">Sign up for newsletter</h3>
                                    <form className="footer-widget__newsletter-form">
                                        <div className="footer-widget__newsletter-input-box">
                                            <input type="email" placeholder="Email Address" name="email" />
                                            <button type="submit" className="footer-widget__newsletter-btn"><i
                                                    className="fas fa-paper-plane"></i></button>
                                        </div>
                                    </form>
                                    <div className="footer-widget__newsletter-bottom">
                                        <div className="footer-widget__newsletter-bottom-icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="footer-widget__newsletter-bottom-text">
                                            <p>I agree to all terms and policies</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="site-footer-bottom__inner">
                                    <p className="site-footer-bottom__copy-right">© Copyright 2022 by <a
                                            href="/">tribesmktng.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;