import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const MobileTours = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Mobile Tours</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Mobile Tours</h3>
                <p class="service-details__text-1">
                  Delivering a mobile tour takes meticulous planning and vision
                  to meet objectives at scale. When developing a mobile
                  experience it’s important to determine the locations to be
                  visited, resources and assets that will be utilized – and how
                  – to achieve and measure established goals. <br />
                  <br />
                  The core components of a successful mobile tour are
                  scalability, execution, and design. The ultimate goal is to
                  not compromise design with scale. Whether it’s for a social,
                  cause or corporate objective; be rest assured, we have you
                  covered. If you want your brand to stand out think
                  differently. The design of mobile vehicle and other
                  collaterals is a participants or consumer’s first impression
                  of the brand . Design is often overlooked but in may ways,
                  design is the most effective way to communicate a message – so
                  make sure to make it count. Now once consumers get to the
                  mobile experience, execution takes over. We maintain a retinue
                  of energetic and passionate brand ambassadors to make an
                  impact. When we pair our network of passionate brand
                  ambassadors with beautifully designed mobile experiences,
                  we’re able to bring value to the lives of consumers and
                  stimulate desired reactions
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default MobileTours;
