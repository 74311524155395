import React, { Component, Fragment } from 'react'
import Clients from '../Home/Clients'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <section class="page-header">
                    <div class="page-header-shape-1"></div>
                    <div class="page-header-shape-2"></div>
                    <div class="container">
                        <div class="page-header__inner">
                            <ul class="thm-breadcrumb list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><span>.</span></li>
                                <li>About us</li>
                            </ul>
                            <h2>About us</h2>
                        </div>
                    </div>
                </section>
                <section class="reasons">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-lg-6">
                                <div class="reasons__left">
                                    <div class="reasons__img">
                                        <img src="assets/images/resources/about_i.jpg" alt="" />
                                        <div class="reasons__img-shape-1"></div>
                                        <div class="reasons__img-shape-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-6">
                                <div class="reasons__right">
                                    <div class="section-title text-left">
                                        <span class="section-title__tagline">About Tribes Marketing</span>
                                        <h2 class="section-title__title">EXPERIENCE MAKES THE DIFFERENCE</h2>
                                    </div>
                                    <p class="reasons__text">In a crowded marketplace, it is crucial for brands to build loyalty with their customers to ensure a lasting and engaging relationship . An effective way to cement this bond is by engaging and exciting consumers in a fun, memorable and relevant experieince.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="we-change">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5 col-lg-6">
                                <div class="reasons__right">
                                    <div class="section-title text-left">
                                        {/* <span class="section-title__tagline">About Tribes Marketing</span> */}<br />
                                        <h2 class="section-title__title">CREATING GREAT EXPERIENCE IS UNCOMMON</h2>
                                    </div>
                                    <p class="reasons__text">Finding an agency that can keep up with you is even harder. Project timelines are tight, plans are in flux and expectation are sky high. We get it. Our process is designed to make working with us easy and effective.</p>
                                    
                                </div>
                            </div>
                            <div class="col-xl-7 col-lg-6">
                                <div class="reasons__left">
                                    <div class="reasons__img">
                                        <img src="assets/images/resources/reasons-img-2.jpg" alt="" />
                                        <div class="reasons__img-shape-1"></div>
                                        <div class="reasons__img-shape-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section class="team-one">
                    <div class="container">
                        <div class="section-title text-center">
                            <span class="section-title__tagline">Team members</span>
                            <h2 class="section-title__title">Meet our experts</h2>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                
                                <div class="team-one__single wow fadeInUp" data-wow-delay="100ms">
                                    <div class="team-one__img-box">
                                        <div class="team-one__img">
                                            <img src="assets/images/team/turton.jpg" alt="" />
                                        </div>
                                        <div class="team-one__social">
                                            <a href="/#"><i class="fab fa-twitter"></i></a>
                                            <a href="/#" class="clr-fb"><i class="fab fa-facebook"></i></a>
                                            <a href="/#" class="clr-dri"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="/#" class="clr-ins"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div class="team-one__member-info">
                                        <h4 class="team-one__member-name">ROTIMI TURTON</h4>
                                        <p class="team-one__member-title">Executive Director</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                
                                <div class="team-one__single wow fadeInUp" data-wow-delay="200ms">
                                    <div class="team-one__img-box">
                                        <div class="team-one__img">
                                            <img src="assets/images/team/grace.jpg" alt="" />
                                        </div>
                                        <div class="team-one__social">
                                            <a href="/#"><i class="fab fa-twitter"></i></a>
                                            <a href="/#" class="clr-fb"><i class="fab fa-facebook"></i></a>
                                            <a href="/#" class="clr-dri"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="/#" class="clr-ins"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div class="team-one__member-info">
                                        <h4 class="team-one__member-name">ALHERI COKER</h4>
                                        <p class="team-one__member-title">Operations Lead</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-xl-3 col-lg-6 col-md-6">
                                
                                <div class="team-one__single wow fadeInUp" data-wow-delay="300ms">
                                    <div class="team-one__img-box">
                                        <div class="team-one__img">
                                            <img src="assets/images/team/team-1-3.jpg" alt="" />
                                        </div>
                                        <div class="team-one__social">
                                            <a href="/#"><i class="fab fa-twitter"></i></a>
                                            <a href="/#" class="clr-fb"><i class="fab fa-facebook"></i></a>
                                            <a href="/#" class="clr-dri"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="/#" class="clr-ins"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div class="team-one__member-info">
                                        <h4 class="team-one__member-name">TAIWO FIJABI</h4>
                                        <p class="team-one__member-title">Developer</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                
                                <div class="team-one__single wow fadeInUp" data-wow-delay="400ms">
                                    <div class="team-one__img-box">
                                        <div class="team-one__img">
                                            <img src="assets/images/team/team-1-4.jpg" alt="" />
                                        </div>
                                        <div class="team-one__social">
                                            <a href="/#"><i class="fab fa-twitter"></i></a>
                                            <a href="/#" class="clr-fb"><i class="fab fa-facebook"></i></a>
                                            <a href="/#" class="clr-dri"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="/#" class="clr-ins"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div class="team-one__member-info">
                                        <h4 class="team-one__member-name">JEPHTAH FOLORUNSO</h4>
                                        <p class="team-one__member-title">Developer</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <Clients />
            </Fragment>
        );
    }
}

export default Content;