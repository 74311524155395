import React, { Fragment } from "react";

function ServiceList() {
  return (
    <Fragment>
      <section className="page-header">
        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Services</li>
            </ul>
            <h2>Services</h2>
          </div>
        </div>
      </section>
      <section className="areas-of-practice">
        <div className="container">
          <div className="areas-of-practice__top">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="areas-of-practice__top-left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Areas of practice
                    </span>
                    <h2 className="section-title__title">
                      Our areas of expertise
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="areas-of-practice__top-right">
                  <p className="areas-of-practice__top-right-text">We taught ourselves through sheer dint of passion, smart work, experience and deep insights to do the things we do well and get noticed for it.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="areas-of-practice__bottom">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-color-sample"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/popupstores">Pop Up And Mobile Stores</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Pop-up/Mobile stores represent an exciting and place for...
                  </p>
                  <a
                    href="/popupstores"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-front-end"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/brandactivation">Brand Activation</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    The ultimate brand experiences are creatively driven by
                    the...
                  </p>
                  <a
                    href="/brandactivation"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-online-shopping"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/brand-product-sampling">
                          Brand/Product Sampling
                        </a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Our world today is noisier than ever. Consumers are
                    constantly...
                  </p>
                  <a
                    href="/brand-product-sampling"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/campus-and-youth-engagement">
                          Campus And Youth Engagement
                        </a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Perceptive brands have the clever ability to position their
                    identities...
                  </p>
                  <a
                    href="/campus-and-youth-engagement"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/talent-procurement-and-management">
                          Talent Procurement And Management
                        </a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    TEPIMS, in essence, is all about find the right mix and fit
                    of need...
                  </p>
                  <a
                    href="/talent-procurement-and-management"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/strategic-corperate-retreat-packaging">
                          Strategic/Corporate Retreats Packaging
                        </a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Every business at some point before, during or after the
                    year’s...
                  </p>
                  <a
                    href="/strategic-corperate-retreat-packaging"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/influencer-marketing">Influencer Marketing</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Social media platforms are ever coming up with new
                    features...
                  </p>
                  <a
                    href="/influencer-marketing"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/guirella-marketing">Guirrella Marketing</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    The trust of Guerrilla marketing is to maximum exposure
                    with...
                  </p>
                  <a
                    href="/guirella-marketing"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/mobile-tours">Mobile Tours</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Delivering a mobile tour takes meticulous planning and
                    vision...
                  </p>
                  <a
                    href="/mobile-tours"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/ideation-and-design">Ideation And Design</a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Our environment provides us a lot of elements to inspire our
                    creativity and thought processes...
                  </p>
                  <a
                    href="/ideation-and-design"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="areas-of-practice__single">
                  <div className="areas-of-practice__icon-box">
                    <div className="areas-of-practice__icon">
                      <span className="icon-graphic-design"></span>
                    </div>
                    <div className="areas-of-practice__title">
                      <h3>
                        <a href="/asset-property-creation">
                          Asset/Property Creation
                        </a>
                      </h3>
                    </div>
                  </div>
                  <p className="areas-of-practice__text">
                    Creating an impactful and enduring property requires the
                    dynamism of a creative mind, the savviness of a...
                  </p>
                  <a
                    href="/asset-property-creation"
                    className="areas-of-practice__read-more"
                  >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ServiceList;
