import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const InfluencerMarketing = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Influencer Marketing</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Influencer Marketing</h3>
                <p class="service-details__text-1">
                  Social media platforms are ever coming up with new features
                  and because of this, the manner people consume and create
                  content is constantly evolving. Influencer marketing to us, is
                  all about using social media to connect brands and client with
                  people who share the same views, interests or goals. We are
                  gifted in designing influencer campaign strategies around
                  specific objectives or causes that directly amplify a brand’s
                  message or story. <br />
                  <br />
                  Unearthing the most relevant influencers is vital but what’s
                  just important if not more important is how you collaborate.
                  We believe that the best way to work with influencers is to
                  co-create with them so we can ensure their voices are
                  expressed in a brand’s message. Influencers are creatives at
                  heart so we must give them the freedom and space they need to
                  create and express.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default InfluencerMarketing;
