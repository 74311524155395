import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const AssetCreation = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Asset/Property Creation</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Asset/Property Creation</h3>
                <p class="service-details__text-1">
                  Creating an impactful and enduring property requires the
                  dynamism of a creative mind, the savviness of a consummate
                  strategist and the vision of a deep thinker. In building an
                  asset or property; it must first be envisioned. Whether it’s a
                  days event , a week long social or entertainment fiesta.,
                  creating an asset must of necessity incorporates deep, precise
                  socio-cultural trends that will elicit emotions at an intimate
                  level. <br />
                  <br />
                  Creating an asset or property is a big-bold thing and intent
                  to disrupt the status quo. Property creation entails building
                  what never existed into something that can not be ignored or
                  passed over with the fortitude to call in other skill sets
                  where required. TM offers an extensive array of successful
                  property and asset creations for a variety of brands and
                  corporates. <br />
                  <br />
                  The unique collection of talents at tribes is suited to build
                  influential property creations from the ground up. we invests
                  the time to understand a brand’s core values and integrate
                  those throughout the property creation process, ensuring every
                  sweeping event and experience resonates with the client’s
                  brand identity, thereby eliciting widespread awareness and
                  engagement among audience/consumers.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AssetCreation;
