import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const CampusYouth = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Campus And Youth Engagement</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">
                  Campus And Youth Engagement
                </h3>
                <p class="service-details__text-1">
                  Perceptive brands have the clever ability to position their
                  identities and values to align with evolving trends and
                  cultural attitudes. The know-how to approach and engage a
                  techie generation with unprecedented levels of digital and
                  social acumen is critical for brands that wish to stay
                  relevant in the coming years. Our campuses spaces represent
                  the future of spending habits and brand loyalty.
                  <br />
                  <br />
                  When most people look back on their lives and discuss where
                  and how they met their best friends, their husbands and wives,
                  and discovered their passions in life, they talk about their
                  early 20s. Those are the years that turn young men and women
                  into adults. It’s a time in life when people form allegiances
                  that last a lifetime – both with people and brands. Chances
                  are you’ve been eating the same brand of plantain chips,
                  chinchin, sausage roll or drink from your most memorable
                  campus event location . That’s just how life works, because
                  that’s how people work. And that’s how intelligent brands work
                  too. <br />
                  <br />
                  At Tribes, we offer a comprehensive and strategic array of
                  insights into college workings and dynamics. Today’s
                  university students are technically savvy and quick to reject
                  brands that make unauthentic attempts at engagement. At
                  Tribes, many of our team members are Millennials who
                  understands the campus environment, nuances, speak, diversity
                  and must offer value, which many students view as a complex
                  blend of substance, utility and innovation.
                  <br />
                  <br />
                  We at Tribes understand the longstanding traditions and values
                  that live-on through students and alumni and why the most
                  effective campus programs synergize brand promise and vision
                  with the energy of students and those who have been there.
                  <br />
                  <br />
                  Pop-up/Mobile stores provide consumers an escape from a
                  traditional brand experience into the unique brand themed
                  milieu. By putting your brand in an environment that is not
                  the norm we can have a more resonating resounding impact on
                  changing perceptions and creating appeal. Consumers are able
                  to experience a brand’s values and this is start of building a
                  connection. If done right, pop-up/mobile stores are positioned
                  to engage consumers at/on a more intimate level.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default CampusYouth;
