import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const BrandProductSampling = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2> Brand/Product Sampling</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title"> Brand/Product Sampling</h3>
                <p class="service-details__text-1">
                  Our world today is noisier than ever. Consumers are constantly
                  inundated with promotions that compete for their attention,
                  taste buds and time every day. Unsurprisingly, media-savvy
                  modern consumers – especially Millennials – are guarded about
                  promotions, and view attempts to engage their valuable
                  thoughts and time with a discerning eye. <br />
                  <br />
                  Buzz fades. Engagement stays. Sampling/trial experiences that
                  do not value the consumer’s sensibility will disappear with
                  countless other failed attempts to engage people in authentic
                  and meaningful ways. Effective sampling experiences resonate
                  with consumers. This is accomplished by identifying values
                  that brands and consumers share, and creating sampling
                  experience that showcase those mutual perspectives while
                  driving trials.
                  <br />
                  <br />
                  Tribes has an extensive and diverse background in developing
                  promotional samplings/trials that are dynamic and fun. These
                  are real-life testing grounds for our team’s innovative ideas
                  where success is defined by consumer actions.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default BrandProductSampling;
