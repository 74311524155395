import React from "react";

function ServiceCategories() {
  return (
    <div className="col-xl-4 col-lg-5">
      <div className="service-details__sidebar">
        <div className="service-details__sidebar-category">
          <h3 className="service-details__sidebar-title">Categories</h3>
          <ul className="service-details__sidebar-category-list list-unstyled">
            <li>
              <a href="/popupstores">
                Pop Up And Mobile Stores
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/brandactivation">
                Brand Activation<span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/brand-product-sampling">
                Brand/Product Sampling
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/campus-and-youth-engagement">
                Campus And Youth Engagement
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/talent-procurement-and-management">
                Talent Procurement And Management
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/strategic-corperate-retreat-packaging">
                Strategic/Corporate Retreats Packaging
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/influencer-marketing">
                Influencer Marketing<span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/guirella-marketing">
                Guirrella Marketing<span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/mobile-tours">
                Mobile Tours<span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/ideation-and-design">
                Ideation And Design<span className="fa fa-angle-right"></span>
              </a>
            </li>
            <li>
              <a href="/asset-property-creation">
                Asset/Property Creation
                <span className="fa fa-angle-right"></span>
              </a>
            </li>
          </ul>
        </div>
        <div className="service-details__need-help">
          {/* <div className="service-details__need-help-bg"
                style="background-image: url(assets/images/resources/ser-dtl-need-help-bg.jpg)">
            </div> */}
          {/* <h3 className="service-details__need-help-title">Need help</h3>
          <p className="service-details__need-help-text">
            Prefer speaking with a human to filling out a form? call corporate
            office and we will connect you with a team member who can help.
          </p>
          <div className="service-details__need-help-phone">
            <div className="service-details__need-help-phone-icon">
              <span className="icon-phone-call"></span>
            </div>
            <div className="service-details__need-help-phone-number">
              <h4>
                <a href="tel:">92 666 888 0000</a>
              </h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ServiceCategories;
