import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Iphone = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img
                  src="assets/images/portfolio/iphone/iphone-re.jpg"
                  alt=""
                />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">Apple</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Activation</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2022</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  Apple, owners of the iPhone 13 mobile phone proposed an
                  in-store experiential engagement to launch iPhone 13 for the
                  Nigerian market
                </p>
                <h2 class="portfolio-fullwidth__title">AGENCY TASK</h2>
                <p class="portfolio-fullwidth__text-1">
                  Apple requires agency to develop, design and package an
                  exciting in-store engagement to excite and connect their
                  target audience with the latest iPhone brand and infuse the
                  iPhone 13 into their lifestyle and mood.
                </p>

                <h2 class="portfolio-fullwidth__title">INSIGHT</h2>
                <p class="portfolio-fullwidth__text-1">
                  Apple iPhone 13 is imbedded with eye-catching technology
                  namely stunning displays, vibrant images, sleek designs and
                  massive memory capacity difficult to resist from all-glass
                  designs to curved edges its built to be the best hand-held
                  device or looking tool in the office
                </p>
                <h2 class="portfolio-fullwidth__title">AGENCY TASK</h2>
                <p class="portfolio-fullwidth__text-1">
                  Apple requires agency to develop, design and package an
                  exciting in-store engagement to excite and connect their
                  target audience with the latest iPhone brand and infuse the
                  iPhone 13 into their lifestyle and mood.
                </p>
                <h2 class="portfolio-fullwidth__title">EXECUTION</h2>
                <p class="portfolio-fullwidth__text-1">
                  With the iPhone 13 theme “Your new Super Power” We engaged the
                  target audience with activities (Pre and during) that captures
                  the essence of Nigeria through the eye of an iPhone. Engaging
                  activities such as Teasers (Vox pop) , sweepstake challenge,
                  Radio OAP, and in-store launch event( Pianist, contemporary
                  classic sound/music rendition, bar with mixologist,
                  traditional troupe, iPhone help desk corner, traditional
                  instrumentalist unboxing iPhone 13).
                </p>
                <p class="portfolio-fullwidth__text-1">
                  The four focus pointers include; Music, video, picture and
                  content. These four pointers are from use of iPhone 13 unique
                  features (Super Retina XDR display with True Tone, an A15
                  Bionic chip, cinematic mood camera, micro photography).
                </p>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/kmv" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/apple-ipad-air" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g3.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g4.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g5.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/iphone/iphone-g6.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Iphone;
