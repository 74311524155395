import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Kewalram = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img
                  src="assets/images/portfolio/kewalram/kewalram-bg.jpg"
                  alt=""
                />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">Kewalram</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Events</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name"> 2010</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  Tribes was mandated to package a high impact corporate event
                  to marks it’s development and evolution.
                </p>
                <h2 class="portfolio-fullwidth__title">THE CONTEXT</h2>
                <p class="portfolio-fullwidth__text-1">
                  KCG is a conglomerate representing a large number of
                  businesses in Africa Asia and the Middle East.
                </p>
                <h2 class="portfolio-fullwidth__title">THE EXECUTION</h2>
                <p class="portfolio-fullwidth__text-1">
                  Tribes provided total event management including RSVP
                  management
                </p>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/onga" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/kewalram" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/kewalram/kewalram-g1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/kewalram/kewalram-g2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/kewalram/kewalram.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Kewalram;
