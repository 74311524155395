import React, { Fragment, useEffect, useState } from "react";
// import NavHelper from "../../helper/NavHelper";

// class Header extends NavHelper {
//   render() {
function Header() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(false);
  }, []);
  function handleOpen() {
    setIsActive(!isActive);
  }

  function handleClick() {
    setIsActive(!isActive);
  }
  return (
    <Fragment>
      <div className="mobilenav-container">
        <div
          className={
            isActive ? "mobile-nav__wrapper expanded " : "mobile-nav__wrapper  "
          }
        >
          <div
            onClick={handleClick}
            className="mobile-nav__overlay mobile-nav__toggler"
          ></div>

          <div className="mobile-nav__content">
            <span
              className="mobile-nav__close mobile-nav__toggler"
              onClick={handleClick}
            ></span>
            <div className="logo-box">
              <a href="/" aria-label="logo image">
                <img src="assets/images/logo/logo.png" width="155" alt="" />
              </a>
            </div>

            <div className="mobile-nav__container">
              <ul className="main-menu__list">
                <li className="dropdown current">
                  <a href="/">
                    Home
                    <button aria-label="dropdown toggler">
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </a>
                </li>
                <li className="dropdown current">
                  <a href="/about">
                    About
                    <button aria-label="dropdown toggler">
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </a>
                </li>
                <li className="dropdown current">
                  <a href="/services">
                    Services
                    <button aria-label="dropdown toggler">
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </a>
                </li>
                <li className="dropdown current">
                  <a href="/portfolio">
                    Portfolio
                    <button aria-label="dropdown toggler">
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </a>
                </li>

                <li className="dropdown current">
                  <a href="/contact">
                    Contact
                    <button aria-label="dropdown toggler">
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </a>
                </li>
              </ul>
            </div>
            <ul className="mobile-nav__contact list-unstyled">
              <li>
                <i className="fa fa-envelope"></i>
                <a href="mailto:needhelp@packageName__.com">
                  info@tribesmktng.com
                </a>
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                <a href="tel:666-888-0000">+234 811 359 6177</a>
              </li>
            </ul>
            <div className="mobile-nav__top">
              <div className="mobile-nav__social">
                {/* <a href="#" className="fab fa-twitter"></a>
                <a href="#" className="fab fa-facebook-square"></a>
                <a href="#" className="fab fa-pinterest-p"></a>
                <a href="#" className="fab fa-instagram"></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="main-header clearfix main-header-three">
        <nav className="main-menu clearfix main-menu-three">
          <div className="main-menu-wrapper clearfix">
            <div className="main-menu-wrapper__left clearfix">
              <div className="main-menu-wrapper__logo">
                <a href="/">
                  <img
                    src="assets/images/logo/Picture.png"
                    width="121"
                    alt=""
                  />
                </a>
              </div>
              <div className="main-menu-wrapper__search-box">
                {/* <a href="/#"className="main-menu-wrapper__search search-toggler icon-magnifying-glass"></a> */}
              </div>
              <div className="main-menu-wrapper__social">
                {/* <a href="/#">
                  <i className="fab fa-twitter"></i>
                </a> */}
                <a href="/#" className="clr-fb">
                  <i className="fab fa-facebook"></i>
                </a>
                {/* <a href="/#" className="clr-dri">
                  <i className="fab fa-pinterest-p"></i>
                </a> */}
                <a
                  href="https://www.instagram.com/tribesmktng/"
                  className="clr-ins"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="main-menu-wrapper__main-menu">
              <a href="#" className="mobile-nav__toggler" onClick={handleOpen}>
                <span></span>
              </a>
              <ul className="main-menu__list">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="main-menu-wrapper__right">
              <div className="main-menu-wrapper__right-contact-box">
                <div className="main-menu-wrapper__right-contact-icon">
                  <span className="icon-phone-call"></span>
                </div>
                <div className="main-menu-wrapper__right-contact-number">
                  <a href="tel:92-666-888-0000">+234 811 359 6177</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  );
}
// }

export default Header;
