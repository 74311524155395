import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Pecadomo = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img
                  src="assets/images/portfolio/pecadomo/pecadomo-re.jpg"
                  alt=""
                />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">FRIESLAND CAMPINA</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Ideation</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2017</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  We aimed to disrupt the milk market in Nigeria and to showcase
                  the usage scenario to which milk can be used in enhancing the
                  nutrition of Nigerians; woven into the kaleidoscope of meals
                  consumed across Nigeria. We felt milk can do more, Nigerians
                  can do more with their milk.
                </p>

                <h2 class="portfolio-fullwidth__title">THE EXECUTION</h2>
                <p class="portfolio-fullwidth__text-1">
                  We created exciting usage scenarios leveraging an array of
                  both local and continental ingredients with milk being the
                  enhancer thereby empowering our communities to have more
                  healthy and exciting meal opportunities; this was showcased
                  deploying a broad range of media to reach the audience.
                </p>
                <h2 class="portfolio-fullwidth__title">THE OUTCOME</h2>
                <p class="portfolio-fullwidth__text-1">
                  The message was compelling that the target audience took
                  ownership of it around Nigeria and expanded it beyond the
                  imagination of the initiators. It received over 200million
                  views, reviews and mention on all media . Campaign was adopted
                  and adapted for 28 African and Asian market where the Peak
                  brand features.
                </p>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/ekopolitan" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/onga" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/pecadomo/pecadomo-g1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/pecadomo/pecadomo-g4.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/pecadomo/pacadomo-g3.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Pecadomo;
