/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Service from "./components/pages/Service";
import PopAndMobileStores from "./components/sections/Services/ServicesPage/PopAndMobileStores";
import AssetCreation from "./components/sections/Services/ServicesPage/AssetCreation";
import BrandProductSampling from "./components/sections/Services/ServicesPage/BrandProductSampling";
import CampusYouth from "./components/sections/Services/ServicesPage/CampusYouth";
import GuirrellaMarketing from "./components/sections/Services/ServicesPage/GuirrellaMarketing";
import IdeationDesign from "./components/sections/Services/ServicesPage/IdeationDesign";
import InfluencerMarketing from "./components/sections/Services/ServicesPage/InfluencerMarketing";
import MobileTours from "./components/sections/Services/ServicesPage/MobileTours";
import StrategicPackaging from "./components/sections/Services/ServicesPage/StrategicPackaging";
import Talent from "./components/sections/Services/ServicesPage/Talent";
import BrandActivation from "./components/sections/Services/ServicesPage/BrandActivation";
import Portfolio from "./components/sections/Portfolio/PortfolioPage";
import Hifl from "./components/sections/Portfolio/PortfolioPages.js/Hifl-19";
import Onga from "./components/sections/Portfolio/PortfolioPages.js/Onga";
import EliteAwards from "./components/sections/Portfolio/PortfolioPages.js/EliteAwards";
import Ekopolitan from "./components/sections/Portfolio/PortfolioPages.js/Ekopolitan";
import Pecadomo from "./components/sections/Portfolio/PortfolioPages.js/Pecadomo";
import Kewalram from "./components/sections/Portfolio/PortfolioPages.js/Kewalram";
import Kmv from "./components/sections/Portfolio/PortfolioPages.js/Kmv";
import Ipad from "./components/sections/Portfolio/PortfolioPages.js/Ipad";
import Iphone from "./components/sections/Portfolio/PortfolioPages.js/Iphone";
import Elite19 from "./components/sections/Portfolio/PortfolioPages.js/Elite19";
import Ideation from "./components/sections/Portfolio/Ideation";
import Events from "./components/sections/Portfolio/Events";
import Activation from "./components/sections/Portfolio/Activation";
import Hifl21 from "./components/sections/Portfolio/PortfolioPages.js/Hifl-21";
import Elite21 from "./components/sections/Portfolio/PortfolioPages.js/Elite21";

export default () => {
  return (
    <Router>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Service} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/popupstores" component={PopAndMobileStores} />
        <Route
          path="/brand-product-sampling"
          component={BrandProductSampling}
        />

        <Route path="/brandactivation" component={BrandActivation} />
        <Route path="/asset-property-creation" component={AssetCreation} />
        <Route path="/campus-and-youth-engagement" component={CampusYouth} />
        <Route path="/guirella-marketing" component={GuirrellaMarketing} />
        <Route path="/ideation-and-design" component={IdeationDesign} />
        <Route path="/influencer-marketing" component={InfluencerMarketing} />
        <Route path="/mobile-tours" component={MobileTours} />
        <Route
          path="/strategic-corperate-retreat-packaging"
          component={StrategicPackaging}
        />
        <Route path="/hifl-2019" component={Hifl} />
        <Route path="/onga" component={Onga} />
        <Route path="/elite-awards-2018" component={EliteAwards} />
        <Route path="/elite-awards-2021" component={Elite21} />
        <Route path="/ekopolitan" component={Ekopolitan} />
        <Route path="/pecadomo" component={Pecadomo} />
        <Route path="/kewalram" component={Kewalram} />
        <Route path="/kwv" component={Kmv} />
        <Route path="/talent-procurement-and-management" component={Talent} />
        <Route path="/apple-ipad-air" component={Ipad} />
        <Route path="/iphone-13" component={Iphone} />
        <Route path="/elite-awards-2019" component={Elite19} />
        <Route path="/ideation" component={Ideation} />
        <Route path="/events" component={Events} />
        <Route path="/activation" component={Activation} />
        <Route path="/hifl-2021" component={Hifl21} />
      </Switch>
    </Router>
  );
};
