import React, { Fragment } from 'react'


function Banner() {
    return (

        <Fragment>
        <div className="stricky-header stricked-menu main-menu main-menu-three">
            <div className="sticky-header__content"></div>
        </div>
        <section className="about-one">
            <div className="about-one__floated-text">portfolio</div>
            <div className="container text-center">
                <h3 className="about-one__subtitle">welcome to Tribes Marketing Agency</h3>
                <h2 className="about-one__title">We create experiential solutions and services that delivers results</h2>
                <div className="about-one__box wow fadeInUp" data-wow-duration="1500ms">
                    <span>Tribes Marketing</span>
                    {/* <p><i className="odometer" data-count="4890">50</i></p> */}
                </div>
            </div>
            </section>
            </Fragment>
    
  )
}

export default Banner