import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const Talent = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Talent Procurement And Management</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">
                  Talent Procurement And Management
                </h3>
                <p class="service-details__text-1">
                  TEPIMS, in essence, is all about find the right mix and fit of
                  need and skills and more importantly bringing them together .
                  Identifying the ideal artiste/performer, artist, celebrity to
                  participate, headline or midwife(ing) – a particular
                  event/programe requires the ability to align values and
                  purposes. TEPIMS assesses celebrities and performers not only
                  on their popularity and pop-culture status, but also their
                  internal passions and motivation. <br />
                  <br />
                  Consumers connect with passion because it is
                  powerful,spontaneous and sincere. Passion is real-it’s life.
                  Passion is accessible. Events that leverage passion to
                  communicate values gain access to the hearts and minds of
                  consumers. Though the performers and venues may change with
                  regards to scope and scale, the principle that drives
                  effective TEPIMS does not. Matching a performer’s values with
                  consumer sensibilities is paramount to creating memorable
                  experiences that resonate with audiences.
                  <br />
                  <br />
                  Though other agencies outsource talent procurement to third
                  parties, At tribes, we handle every facet of these business
                  relationships in-house. This model allows us to carefully
                  streamline the processes, which reduces costs, saves time or
                  mitigate any possible risk or conflict; by these we are able
                  to offer our clients exceptional personalities to bring their
                  brands and events to life.
                  <br />
                  <br />
                  We experience the world through our five senses – taste,
                  sight, smell, touch, and sound – which leaves traditional
                  marketing severely limited in terms of long-term impact. Brand
                  Activations, however, create memories through experiences, and
                  memories are extraordinarily powerful.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Talent;
