import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const GuirrellaMarketing = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Guirrella Marketing</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">Guirrella Marketing</h3>
                <p class="service-details__text-1">
                  The trust of Guerrilla marketing is to maximum exposure with
                  minimal spend while evading, rewriting convention. Guerrilla
                  marketing requires a spontaneous, adventurous mindset and
                  attitude and client’s who aren’t afraid to step outside the
                  box of their comfort zone in order to create a memorable
                  impact with consumers and the publics.
                  <br />
                  <br />
                  Most guerilla marketing initiatives do not last for long, but
                  if done right, they can strongly resonate with the public long
                  after the event. The most effective and strongest guerilla
                  marketing campaigns are disruptive in nature and they are able
                  to create memorable experiences by flaunting engrained rules
                  and expectations.
                  <br />
                  <br />
                  We understands at Tribes that true creativity does not exist
                  without a desire to push ideas beyond their accepted
                  boundaries. The human condition wants stability and routine;
                  the human spirit, however, craves disruption, adventure, and
                  new experiences. Tribes actively nurtures a creative culture
                  in our office that encourages our employees to think boldly,
                  outside the box and take risks. We always approach guerrilla
                  marketing with three basic tools: a massive white board, a
                  handful of colorful markers, and a room full of people free to
                  explore with no limitations or boundaries.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default GuirrellaMarketing;
