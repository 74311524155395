import React, { Fragment } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ServiceCategories from "../ServiceCategories";

const StrategicPackaging = () => {
  return (
    <Fragment>
      <Header />
      <section class="page-header">
        <div class="page-header-shape-1"></div>
        <div class="page-header-shape-2"></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>.</span>
              </li>
              <li>Service Details</li>
            </ul>
            <h2>Strategic/Corporate Retreats Packaging</h2>
          </div>
        </div>
      </section>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-details__left">
                {/* <div class="service-details__img">
                  <img
                    src="assets/images/resources/service-details-img.jpg"
                    alt=""
                  />
                </div> */}
                <div class="service-details__icon-box">
                  <span class="icon-front-end"></span>
                </div>
                <h3 class="service-details__title">
                  Strategic/Corporate Retreats Packaging
                </h3>
                <p class="service-details__text-1">
                  Every business at some point before, during or after the
                  year’s business requires some moment of self and business
                  review(reflection) and more importantly to get or bring all on
                  it’s boat up to steam on it chosen direction or cause. At
                  Tribes, we know what this means to every business and we
                  understand the requirement of such setting or gatherings .{" "}
                  <br />
                  <br />
                  We have designed, built and retained a set of matrix that
                  ensures, we not only meet but surpass the expectations of our
                  client and partners with regards to flawless execution through
                  adequate deployment of key elements resource and support
                  personnel who help ensure the right audience receives the
                  right experience.
                </p>
              </div>
            </div>
            <ServiceCategories />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default StrategicPackaging;
