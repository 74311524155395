import React from "react";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";

const Ipad = () => {
  return (
    <div>
      <Header />
      <section class="portfolio-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="portfolio-fullwidth__img-box">
                <img src="assets/images/portfolio/apple/apple-bg.jpg" alt="" />
                <div class="portfolio-fullwidth__details-box">
                  <ul class="portfolio-fullwidth__details-info list-unstyled">
                    <li>
                      <h5 class="portfolio-fullwidth__client">Clients:</h5>
                      <p class="portfolio-fullwidth__name">Apple</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Category:</h5>
                      <p class="portfolio-fullwidth__name">Activation</p>
                    </li>
                    <li>
                      <h5 class="portfolio-fullwidth__client">Date:</h5>
                      <p class="portfolio-fullwidth__name">2022</p>
                    </li>
                    <li>
                      <div class="portfolio-fullwidth__social-list">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" class="clr-fb">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#" class="clr-dri">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#" class="clr-ins">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-fullwidth__content">
                <h2 class="portfolio-fullwidth__title">THE BRIEF</h2>
                <p class="portfolio-fullwidth__text-1">
                  Apple, owners of the iPad Air has tasked Tribes marketing to
                  develop elements and manage the new iPad Air in-store
                  activation and graphic drawing competition.
                </p>
                <h2 class="portfolio-fullwidth__title">AGENCY TASK</h2>
                <ul class="portfolio-fullwidth__text-1">
                  Brand promoters engaged 2000+ consumers within the mall
                  premises.
                  <li>
                    We engaged a professional graphics artist to draw creative
                    images and ideas using the iPad Air, the product in focus.{" "}
                  </li>
                  <li>
                    Set up of wow selfie mirror booth for audience participation
                    and digital documentation.
                  </li>
                </ul>

                <h2 class="portfolio-fullwidth__title">THE OUTCOME</h2>
                <ul class="portfolio-fullwidth__text-1">
                  <li>
                    Provided the partners/brand with active visibility and ample
                    engagement opportunities with their target audience.
                  </li>
                  <li>
                    Over 1000 consumers were engaged both at the experience
                    point and in the store.
                  </li>
                  <li>200+ footfall at the digital artist experience point.</li>
                  <li>
                    50+ amateur graphic challengers entered for the digital
                    drawing competition.
                  </li>
                </ul>
              </div>
              <div class="portfolio-detail__pagination-box portfolio-fullwidth__pagination-box">
                <ul class="portfolio-detail__pagination list-unstyled">
                  <li class="next">
                    <a href="/iphone-13" aria-label="Previous">
                      <i class="icon-right-arrow"></i>Previous
                    </a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="count">
                    <a href="#"></a>
                  </li>
                  <li class="previous">
                    <a href="/elite-awards-2021" aria-label="Next">
                      Next<i class="icon-right-arrow"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="similar-work">
        <div class="container">
          <div class="section-title text-center">
            {/* <span class="section-title__tagline">Checkout more work</span> */}
            <h2 class="section-title__title">Photo Gallery</h2>
          </div>
          <div className="row filter-layout masonary-layout">
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item illus photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus">
              <div className="portfolio-one__single ">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g3.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g4.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g8.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra photo">
              <div className="portfolio-one__single">
                <div className="portfolio-one__img">
                  <img
                    src="assets/images/portfolio/apple/apple-g7.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 filter-item bra">
              <div className="portfolio-one__single"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ipad;
